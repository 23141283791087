@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root {
  --primary-color-base: #455a64;
  --primary-color-light: #718792;
  --primary-color-dark: #1c313a;

  --secondary-color-base: #69efad;
  --secondary-color-light: #9fffdf;
  --secondary-color-dark: #2bbc7d;
}

.center{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
}



body {
  background-color: var(--primary-color-dark);
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-family: 'Open Sans', sans-serif;

}

.medium-spacing{
  word-spacing: 10px;
}


.textcolor-primary{
  Color: White;

}

.textcolor-alt{
  Color: #2bbc7d;
}

.primary-base{
  Color: White;
  background-color: var(--primary-color-base);
}

.primary-light{
  Color: White;
  background-color: var(--primary-color-light);
}

.primary-dark{
  Color: White;
  background-color: var(--primary-color-dark);
}

.secondary-base{
  Color: Black;
  background-color: var(--secondary-color-base);
}

.secondary-light{
  Color: Black;
  background-color: var(--secondary-color-light);
}

.secondary-dark{
  Color: Black;
  background-color: var(--secondary-color-dark);
}

.nav-menu{
      height:100%;  
}

.div-content{
  width: 75%;
  color: white;

}

@media (min-width: 640px){
.div-content{
  max-width: 600px;
  min-width: 200px;
  color: whtie;

  }
}
.p-align-l{
  text-align: left;
  padding-left: 30px;
}

.project-cell{
  width: 300px;
  height: 300px;
}

.project-grid-icon{
  height: 35px;
  width: 35px;
  padding-right: 5px;
}

.right-image{
  float:right;
}
